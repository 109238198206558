import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
 
const GlobalStyle = createGlobalStyle`
  ${reset};

  html,
  body {
    min-height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.4;
    background: linear-gradient(125deg, rgb(255 255 255) 0%, rgb(255 255 255) 60%, rgb(249 249 249) 53%, rgb(255 255 255) 100%);
    word-break: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  header {
    background: #ffffff0d;
    box-shadow: 0px 3px 16px -5px #ebebeb;
  }
 
  h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0 0 1.45rem;
    font-family: Neuton, serif;
    font-weight: 700;
    line-height: 1.1;
    text-rendering: optimizeLegibility;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.62671rem;
  }

  h3 {
    font-size: 1.38316rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.85028rem;
  }

  h6 {
    font-size: 0.78405rem;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.4;
  }

  dd,
  dl,
  figure,
  p {
    padding: 0;
    margin: 0 0 1.45rem;
  }

  strong,
  b {
    font-weight: bold;
  }
`;
 
export default GlobalStyle;